import { useRef, useState } from "react";
import { addElemToUseStateArr, removeElemFromUseStateArr, replaceElemToUseStateArr } from "../../utils/appUtils";

export const SubscriptionsInputBox = ({subscriptions, setSubscriptions}) => {

    const inputRefs = useRef([]);
    
    const addSubscription = (newSubscription) => {
        addElemToUseStateArr({ setArr: setSubscriptions, elem: newSubscription });
    };

    const removeSubscription = (indexToRemove) => {
        removeElemFromUseStateArr({ setArr: setSubscriptions, arr: subscriptions, index_1: indexToRemove });
    };

    const replaceSubscription = (newValue, indexToReplace) => {
        replaceElemToUseStateArr({ setArr: setSubscriptions, newValue, indexToReplace });
    };

    const onChange = (e) => {
        const index = e.target.dataset.key; // Индекс элемента
        const value = e.target.value; // Текущее значение input
        handleChangeSubcsriptionsArr(value, index); // Обрабатываем изменение
    };


    const addNewStyle = (id) => {
        document.getElementById(id).classList.add("new-input")   
    }

    const removeNewStyle = (id) => {
        document.getElementById(id).classList.remove("new-input")   
    }
    const onKeyDown = (e) => {
        const index = e.target.dataset.key; // Индекс элемента
        const isEmpty = e.target.value === '';
    

        // Если нажата клавиша Backspace или Delete и input пустой
        if (isEmpty && (e.key === 'Backspace' || e.key === 'Delete')) {
            removeSubscription(parseInt(index, 10)); // Удаляем поле с пустым значением
           
        }
    };

    const handleChangeSubcsriptionsArr = (value, index) => {
        const idx = parseInt(index, 10);
        console.log("subscriptions", subscriptions)

        // Если текущее значение пустое
        if (value === "") {

            if (idx > 0) {
                removeSubscription(idx);
                return true
            }

            // console.log("subscriptions[idx]", subscriptions[idx])
            // if (subscriptions[idx] && subscriptions[idx] == "" && idx !== 0) {
            //     removeSubscription(idx);
            //     return true;
            // }
           
            // if (subscriptions[idx + 1] === "" && idx + 1 < subscriptions.length) {
               
            //     removeSubscription(idx);
            //     return true
            // }
            // if (idx > 0 && subscriptions[idx - 1] === "") {
                
            //     removeSubscription(idx);
            //     return true
            // }
            replaceSubscription(value, idx);
            return false; 
        }

      
        
        // Если это новое поле, добавляем подписку
        if (idx === subscriptions.length) {
            addSubscription(value);
            setTimeout(() => {
                if (inputRefs.current[idx]) {
                    inputRefs.current[idx].focus();
                }
            }, 0);
            return true;
        }

        // Заменяем существующее значение
        replaceSubscription(value, idx);
        return false;
    };

    return (
       
        <div className="subscriptions-input-box">
        
            <span className="form-input default-subscription">{subscriptions[0]}</span>
            {subscriptions.slice(1).map((subscription, index) => (
                <input
                    className="form-input"
                    type="text"
                    id={`subscription_${index + 1}`}
                    data-key={index + 1}
                    key={index + 1}
                    onChange={onChange}
                    name={`subscription_${index + 1}`}
                    value={subscription} 
                    required
                    maxLength={256}
                    ref={el => inputRefs.current[index + 1] = el}
                />
            ))}
            <input
                className="form-input new-input"
                type="text"
                id={`subscription_${subscriptions.length}`}
                key={subscriptions.length}
                data-key={subscriptions.length}
                onChange={onChange}
                name={`subscription_${subscriptions.length}`}
                value=""
                maxLength={256}
                onFocus={() => removeNewStyle(`subscription_${subscriptions.length}`)}
                onBlur={() => addNewStyle(`subscription_${subscriptions.length}`)}
                ref={el => inputRefs.current[subscriptions.length] = el}
            />
        </div>
    );
};