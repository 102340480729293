import { useEffect, useState } from "react"


export const PopupWindow = ({message, status, addClassName, setStatus}) => {
    const [visible, setVisible] = useState(true)

    const can_be_closed = status === "loading" ? false : true
    useEffect(() => {
       
        if (can_be_closed) {
            const timer = setTimeout(() => {
                setVisible(false);
                if (setStatus) {
                    setStatus(null)
                }
            }, 3000);

           
            return () => clearTimeout(timer);
        }
    }, [can_be_closed, visible]); 


    return (
        <>
           {visible && (
            
                <div className={`popup-window ${addClassName ? addClassName : ''}`}>
                    <p className="popup-message">{message}</p>  
                </div>

           )
           } 

        </>
       
    )
}

export const PopupConnectWallet = ({status}) => {
    if (!status) {
        return
    }
    
    const message = status === "loading" ? "Your account is connecting..." : status === "error" ? "The wallet is not connected" : "Wallet connected"
    return (
        <>
            <PopupWindow message={message} status={status} addClassName={"wallet-pop-up"} />
        </>
    )

}

export const PopupMinter = ({status}) => {

    if (!status) {
        return
    }
    
    const message = status === "loading" ? "Data is sent to the server..." : status === "error" ? "Error sending data! Please, try again later" : "Data sent"
    return (
        <>
            <PopupWindow message={message} status={status} />
        </>
    )

}

export const PopupTransactionFailed = ({status}) => {

    if (status !== "error") {
        return
    }
    
    const message = "Transaction failed. Please, try again"
    return (
        <>
            <PopupWindow message={message} status={status} />
        </>
    )

}

export const PopupFormErrorMessage = ({message, setStatus}) => {
    console.log("PopupFormErrorMessage", message)
    if (!message) {
        return
    }

    return (
        <>
            <PopupWindow message={message} status={"error"} setStatus={setStatus} />
        </>
    )
}

export const PopupChecker = ({status, setStatus}) => {

    if (!status) {
        return
    }
    
    const message = status === "loading" ? "Data is sent to the server..." : status === "error" ? "Error sending data! Please, try again later" : "Data sent"
    return (
        <>
            <PopupWindow message={message} status={status} setStatus={setStatus} />
        </>
    )

}