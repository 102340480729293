import { replaceElemToUseStateArr } from "./appUtils";


export const checkSubscriptions = (subscriptions_string) => {
       
    const str_arr = subscriptions_string.split(",");
    var subscriptions = [];
    for (let i=0; i<str_arr.length; i++) {
        if (str_arr[i] === "") {
            continue
        }
        const value_trim = str_arr[i].trim();
        const regex = /^[^@,:"/\\| ]*$/;  
        const sub = value_trim.substring(1, value_trim.length - 1);
        if (value_trim[0] !== "@" || !regex.test(sub)) {
            console.log("error");
            return null;
        }
        subscriptions.push(value_trim);
    }
    return subscriptions;
}

export const checkSubscriptionsNew = (subscriptions) => {
       
    var new_subscriptions = []
    for (let i=0; i<subscriptions.length; i++) {
        const subscription = subscriptions[i]
        const value_trim = subscription.trim();        
        const regex = /^[^@,:"/\\| ]*$/;  
        const sub = value_trim.substring(1, value_trim.length - 1);
        if (value_trim[0] !== "@" || !regex.test(sub)) {
            console.log("error");
            return null;
        }
        new_subscriptions.push(value_trim)
    }
    
   return new_subscriptions
}


export const isBase64Image = (string) => {
    const base64Regex = /^data:image\/(png|jpg|jpeg|gif|bmp|webp|svg\+xml);base64,[A-Za-z0-9+/=]+$/;
    return base64Regex.test(string);
  }



export const getCurrentDatetimeLocal = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Добавляем 1, так как месяцы начинаются с 0
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
  
    return `${year}-${month}-${day}T${hours}-${minutes}`;
  }

export const isImageBlob = (data) => {
    return data instanceof Blob;
};