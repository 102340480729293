import './App.css';
import React, { useState, useEffect } from "react";
import { TonConnectUIProvider, TonConnectButton, useTonWallet, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import Header from './components/Header';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import Home from './pages/Home'
import ThemeSwitch from './components/ThemeSwitch';
import {Minter} from './pages/Minter';
import { CollectionInfo } from './pages/CollectionInfo';
import { NftBurning } from './pages/NftBurning';
import { Drop } from './pages/Drop';
import { PopupConnectWallet } from './components/General/PopupWindow';


const App = () => {

  
  const [searchParams, setSearchParams] = useSearchParams();

 
  const [isWalletStatus, setWalletStatus] = useState(null)
   const [chatId, setChatId] = useState(null)


  const [tonConnectUI] = useTonConnectUI();
  

  const wallet = useTonWallet();

  const disconnectHandler = () => {
    if (wallet) {
      tonConnectUI.disconnect()
    }
  }

  const connectHandler = async () => {
    if (!wallet) {
      await tonConnectUI.openModal()
    }
  }

  const walletAddress = useTonAddress();

  console.log("chatId", chatId)
 
  useEffect(() => {
    const handleConnectionRestored = async () => {
      setWalletStatus("loading")
      const restored = await tonConnectUI.connectionRestored;
      if (restored) {
        setWalletStatus("success");
      } else {
        setWalletStatus("error");
      }
    };

    handleConnectionRestored(); // Вызов функции при монтировании компонента

  }, [tonConnectUI]); // Добавляем tonConnectUI в зависимости
  
 
  
  return (
    


          <div className="container">
          <Header address={walletAddress ? `${walletAddress.slice(0, 4)}...${walletAddress.slice(-3)}` : null} onDisconnect={disconnectHandler} handleConnect={connectHandler} /> 
          <Routes>
            <Route path="/" element={<Home wallet={wallet} searchParams={searchParams} setChatId={setChatId} isWalletStatus={isWalletStatus} />} />
            <Route path="/minter" element={<Minter wallet={wallet} tonConnectUI={tonConnectUI} chat_id={chatId} isWalletStatus={isWalletStatus}  />} />
            <Route path="/checker" element={<CollectionInfo wallet={wallet} tonConnectUI={tonConnectUI} isWalletStatus={isWalletStatus}  />} />
            <Route path="/burn" element={<NftBurning wallet={wallet} tonConnectUI={tonConnectUI} isWalletStatus={isWalletStatus}  />} />
            <Route path="/drop" element={<Drop wallet={wallet} tonConnectUI={tonConnectUI} isWalletStatus={isWalletStatus}  />} />
          </Routes>     

          <ThemeSwitch />

          
          </div>
     
  );
}
export default App;

