import axios from "../axios";
import placeholder from "../img/imagePlaceholder.png"
import avatarPlaceholder from "../img/avatar_placeholder.png"


export const checkPassword = async (event_id, password) => {
    try {
        const {data} = await axios.post(`/checker/check_password/`, {
            event_id: event_id, 
            password: password
        })
        return data.is_equal
    } catch (error) {
        console.log(error)
        return false;
    }
}

const getLogo = async (collection_name) => {
    return `/collections/${collection_name}/logo.jpg`

    // try {
    //     const {data} = await axios.get(`/collections/${collection_name}/logo.png`)
    //     return data
    // } catch (error) {
    //     console.log(error)
    //     return placeholder;
    // }
}

const getAvatar = async (subscription_link) => {
    try {
        const {data} = await axios.post(`/checker/channel_avatar/`, {
            channel_url: subscription_link
        })
        return data.url
    } catch (error) {
        console.log(error)
        return placeholder;
    }
}

const getAvatarsArr = async (subscriptions) => {
    console.log("getAvatarsArr", subscriptions)
    var data = []
    for (let i=0; i<subscriptions.length; i++) {
        const channel = "https://t.me/" + subscriptions[i].substring(1, subscriptions[i].length);
        const avatar = await getAvatar(channel);
        data.push(avatar);
    }
    return data;
}

const getDescription = async (collection_name) => {
    try {
        const {data} = await axios.get(`/collections/${collection_name}/metadata/${collection_name}.json`)
        return data.description
    } catch (error) {
        console.log(error)
    }
}

const getParams = async (event_id) => {
    console.log("getParams event_id", event_id)
    try {
        const req = {
            event_id: event_id
        }
        const response = await axios.post(`/checker/event_info/`, req)
        const data = response.data.event_info
        console.log("getParams data", data)
        return {
            start_date: data.start_date,
            end_date: data.end_date,
            invites: data.invites,
            subscriptions: data.subscriptions,
            minted_nfts: data.minted_nfts,
            logo: data.logo_url,
            description: data.description,
            collection_name: data.collection_name
        }
    } catch (error) {
        console.log(error)
        
    }
}

export const setInitialParams = async ({event_id, setCollectionParams}) => {
    try {
        
        const params = await getParams(event_id);
        console.log("params", params)
        const avatars_arr = await getAvatarsArr(params.subscriptions);
        setCollectionParams(prevValues => ({
            ...prevValues, 
            ...params,
            subscriptions_avatar_urls: avatars_arr
        }));   
    } catch (error) {
        // setCollectionParams ({
        //     collection_name: collection_name,
        //     start_date: `2024-08-30T00:00`,
        //     end_date: `2024-09-07T00:00`,
        //     invites: 5,
        //     subscriptions: ["@channel1", "@channel2", "@channel3"],
        //     logo: placeholder,
        //     description: "Collection Description",
        //     subscriptions_avatar_urls: [placeholder, placeholder, placeholder]

        // })

        setCollectionParams({
            collection_name: "",
            description: "",
            logo: "",
            start_date: "",
            end_date: "",
            invites: 0,
            subscriptions: [],
            subscriptions_avatar_urls: [],
            minted_nfts: 1,
        })
        // setCollectionParams({
        //     collection_name: "",
        //     description: "",
        //     logo: "",
        //     start_date: "",
        //     end_date: "",
        //     invites: 0,
        //     subscriptions: ["@rasfocus", "@FarmerDao", "@channel1"],
        //     subscriptions_avatar_urls: [placeholder, placeholder, placeholder],
        //     minted_nfts: 1,
        // })
        console.log(error)
    }
}

export const sendDataToServer = async (send_json) => {
    try {
        const {data} = await axios.post(`/checker/send_nft/`, send_json)
        if (data.status === "success") {
            return true
        }
        console.log("Error:",data.description);
        return false

       
    } catch (error) {
        console.log(error)
        return false
    }
}

// 1) При подключенном кошельке нужно отправить запрос на /checker/user_info/ с json, в котором будет ключ "wallet_address". По идее, нужно сначала вообще требовать от пользователя подключения кошелька, а только после разрешать выполнять условия для получения NFT

// 2) Запрос на /checker/user_info/ вернет в случае успеха json с ключами "status" и "user_info", в значении которого будет словарь с ключом "visited_channels", в котором уже хранится список со ссылками на посещенные каналы. Если пользователя не будет в базе данных и его не удастся туда добавить, либо произойдет ошибка при попытке найти список посещенных каналов, то вернется ошибка 500 и json с ключом "status" и "description"

// 3) При нажатии пользователем на ссылку на канал нужно отправлять запрос на /checker/add_visited_channel/ с ключами
//  "wallet_address", "channel"(ссылка на канал). В случае успешного добавления в базу данных вернет ключ "status" со значением "success"

export const getUserInfo = async (wallet_address) => {
    const sending_json = {
        wallet_address: wallet_address
    }
    try {
        const {data} = await axios.post(`/checker/user_info/`, sending_json)
        if (data.status === "success") {
            return data.user_info.visited_channels
        }
        console.log("Error:",data.description);
        

       
    } catch (error) {        
        console.log(error)
        
    }

}

export const addVisitedChannel = async (wallet_address, channel) => {
    const sending_json = {
        wallet_address: wallet_address,
        channel: channel
    }
    try {
        const {data} = await axios.post(`/checker/add_visited_channel/`, sending_json)
        if (data.status === "success") {
            return true
        }
        return false

       
    } catch (error) {
        console.log(error)
    }

}

