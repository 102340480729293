import React, { useState } from 'react';
import '../Header.css';

const PopupMenu = ({ address, onDisconnect, active }) => {
    const [isOpen, setIsOpen] = useState(false);
   

    const handleCopyAddress = () => {
        navigator.clipboard.writeText(address);
        alert('Address copied to clipboard');
        setIsOpen(false);
    };

    const handleDisconnect = () => {
        onDisconnect();
        setIsOpen(false);
    };

    // Закрытие меню при клике вне его
    const handleClickOutside = (event) => {
        if (event.target.closest('.popup-menu')) return;
        setIsOpen(false);
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
       

        <div>
            
            <span onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer', display: 'inline-block' }}>
                {address} {/* Здесь вы можете отображать адрес или другой элемент */}
            </span>
            {(isOpen && active) && (
                <div className="popup-menu">
                    <div className="popup-menu-item" onClick={handleCopyAddress}>
                        Copy Address
                    </div>
                    <div className="popup-menu-item" onClick={handleDisconnect}>
                        Disconnect
                    </div>
                </div>
            )}
        </div>
    );
};

export default PopupMenu