import './ThemeSwitch.css';
import Switch from "react-switch";




const ThemeSwitch = () => {
    return (
        <Switch onChange={() => {}} className='react-theme-switch'
        checked={true} 
        disabled 
        uncheckedIcon={false} 
        checkedIcon={false}
        handleDiameter={25}
        height={38} 
        width={72}
        onColor="transparent"
        offColor="transparent"
        onHandleColor="transparent"
        offHandleColor="transparent" 
    />
    )
}

export default ThemeSwitch