import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, Route, Routes, useNavigate, useSearchParams } from "react-router-dom"
import "./Minter.css"
import placeholder from "../img/loadImagePlaceholder.png"
import { MinterFormTextValidator } from "../validators/minterFormsValidator";
import qrPlaceholder from "../img/qr-placeholder.jpg"
import NumberSelector from "../components/Minter/NumberSelector";
import DateTimeInput from "../components/General/DateTimeInput";
import { checkSubscriptionsNew, getCurrentDatetimeLocal, isBase64Image } from "../utils/minterUtils";
import tonToUSD from "../utils/tonToUSD";
import { TonConnectButton } from "@tonconnect/ui-react";
import { getApiWallet, getComission, getRandomImage, sendDataToBot, sendMinterData } from "../utils/minterConnections";
import QRCode from "qrcode.react";
import { SubscriptionsInputBox } from "../components/Minter/SubscriptionsInputBox";
import { PopupFormErrorMessage, PopupMinter, PopupTransactionFailed, PopupConnectWallet } from "../components/General/PopupWindow";



const getFileUrl = file => new Promise((resolve, reject) => {

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => {
        console.log(error);
        reject(null)
    };
});



export const Minter = ({wallet, tonConnectUI, chat_id, isWalletStatus}) => {

    const [eventId, setEventId] = useState(null)

   // const initial_subscriptions = "@[channel1], @[channel2],..."

    const initial_subscriptions = ["@Lidumapp"]

    const initialValues = {
        name: "",
        description: "",
        count: "count",
        start: "",
        end: "",
        password: "",
        subscriptions: initial_subscriptions,
        descriptionOfYourPost: "",
        button: "",
        file_name: "",
        file_base64: "",
    };

   const [comission, setComission] = useState(null)
   const [comissionToUSD, setComissionToUSD] = useState(null)
   const [apiWallet, setApiWallet] = useState(null)
   const descriptionRef = useRef(null);
   const descriptionOfYourPostRef = useRef(null); 

   const [sendStatus, setSendStatus] = useState(null)
   const [isTransactionError, setIsTransactionError] = useState(null)
   const [formErrorMessage, setFormErrorMessage] = useState(null)

   
   

    const [pageNumber, setPageNumber] = useState(1)
    const [selectedFileUrl, setSelectedFileUrl] = useState(null)
    const [formValues, setFormValues] = useState(initialValues)
    const [qrCodeLink, setQRCodeLink] = useState(null)

    const [name, setName] = useState(initialValues.name)
    const [description, setDescription] = useState(initialValues.description)
    const [count, setCount] = useState(initialValues.count)
    const [start, setStart] = useState(initialValues.start)
    const [end, setEnd] = useState(initialValues.end)
    const [password, setPassword] = useState(initialValues.password)
    const [subscriptions, setSubscriptions] = useState(initialValues.subscriptions)
    // const [invite, setInvite] = useState(initialValues.invite)
    const [descriptionOfYourPost, setDescriptionOfYourPost] = useState(initialValues.descriptionOfYourPost)
    const [button, setButton] = useState(initialValues.button)

    useEffect(() => {
        if (descriptionRef.current) {
            descriptionRef.current.style.height = 'auto'; // Сбрасываем высоту
            descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`; // Устанавливаем высоту на основе содержимого
        }
        if (descriptionOfYourPostRef.current) {
            descriptionOfYourPostRef.current.style.height = 'auto'; // Сбрасываем высоту
            descriptionOfYourPostRef.current.style.height = `${descriptionOfYourPostRef.current.scrollHeight}px`; // Устанавливаем высоту на основе содержимого
        }
    }, [description, descriptionOfYourPost]);
    

    
    const handleComission = async (collection_cnt) => {

        const result = await getComission({
            wallet_address: tonConnectUI.account.address,
            collection_images_cnt: collection_cnt
        })
        if (result) {
            setComission(result)
            const response = await tonToUSD(result);
            if (response) {
                setComissionToUSD(response)
            }
        }
        
    }
    const handleConnect = async () => {
        try {
            await tonConnectUI.openModal()
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    };

   



    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (file) {
           
            
            const fullFileUrl = await getFileUrl(file)
            setFormValues(prevValues => ({
                ...prevValues, 
                file_name: file.name,
                file_base64: fullFileUrl
            }));
           
            return fullFileUrl
        }
        else {
            return null
        }

    }

    const LoadImage = () => {

        return (
            <div className="load-file-container">
                <div className="collection_image_container">

                    <img src={selectedFileUrl ? selectedFileUrl : placeholder} alt="Collection" className="img" id="loaded_img" />

                </div>
                <input type="file" id="file_input" className="load_img" accept="image/*" onChange={async (e) => {
                    const fileUrl = await handleFileChange(e)
                    
                    if (fileUrl) {
                        setSelectedFileUrl(fileUrl)
                        document.getElementById("loaded_img").src = fileUrl;
                        
                    }


                }
                } />
            </div>
        )
    }

    const ArrowBox = ({ pageNumber }) => {

        return (

            <div className="arrow-box">
                <BackArrowButton pageNumber={pageNumber} />
                <NextArrowButton pageNumber={pageNumber} />
            </div>

        )
    }

   

    const handleSubmit = (e, pageNumber, fields) => {
        
   
        e.preventDefault();
        if (!selectedFileUrl) {            
            setFormErrorMessage("Select an image!")
            return
        }
        
        if (fields.subscriptions) {
            if (!checkSubscriptionsNew(fields.subscriptions)) {
                setFormErrorMessage('Invalid subscription input! Subscriptions must start with "@" and must not contain illegal characters!')
                return
            }
            console.log("checkSubscriptions(fields.subscriptions)", checkSubscriptionsNew(fields.subscriptions))
            
            fields.subscriptions = checkSubscriptionsNew(fields.subscriptions)
        }
        if (fields.start && fields.end) {
            const startDate = new Date(fields.start);
            const endDate = new Date(fields.end);
            const currentDate = new Date();
            if (startDate < currentDate) {
                setFormErrorMessage("The start date must be later than the current time!")
                return
            }
            if (endDate <= startDate) {
                setFormErrorMessage("The start date is greater than the end date!")
                return
            }
            
            
        }
     
        

        setFormValues(prevValues => ({
            ...prevValues, // Сохраняем ранее установленные значения
            ...fields
        }));

        

        
        if (pageNumber === 2) {
            sendDataToServer()
        }
        else {
            if (pageNumber !== pagesArr.length - 1) {
                setPageNumber(pageNumber + 1)
            }
        } 

        

       
        


    };


    const handleFocusCount = (e) => {
   

        if (e.target.value === "count") {
            setCount(""); 
        }
    };

    const handleBlurCount = (e) => {

       
        if (e.target.value === "") {
            setCount("count");
        }
    };

    const handleFocusSubscritions = (e) => {
   
        document.getElementById('subscriptions').classList.remove("subscriptions_initial")
        if (e.target.value === initial_subscriptions) {
            setSubscriptions(""); 
        }
    };

    const handleBlurSubscritions = (e) => {

        
        if (e.target.value === "") {
            document.getElementById('subscriptions').classList.add("subscriptions_initial")
            setSubscriptions(initial_subscriptions);
        }
    };

    const getBase64ImageExtention = (base64Image) => {
        const [header, base64Data] = base64Image.split(',');

        // Проверяем, начинаются ли данные с префикса data: и который тип
        if (header.startsWith('data:')) {
            const mimeType = header.match(/:(.*?);/)[1]; // Извлекаем MIME-тип

            let extension;

            switch (mimeType) {
                case 'image/png':
                    extension = 'png';
                    break;
                case 'image/jpeg':
                    extension = 'jpg';
                    break;
                case 'image/gif':
                    extension = 'gif';
                    break;
                case 'image/bmp':
                    extension = 'bmp';
                    break;
                case 'image/webp':
                    extension = 'webp';
                    break;
                default:
                    extension = '';
                    break;
            }

                console.log(`Расширение: ${extension}`);
                return extension;
            } else {
                console.log('Не удалось определить тип данных.');
                return "";
            }
    }

    
    const handleRandomGen = async () => {
        
        const image = await getRandomImage()
       

        if (image)
        {
            const extension = getBase64ImageExtention(image)
            setSelectedFileUrl(image)
            document.getElementById("loaded_img").src = image;
            setFormValues(prevValues => ({
                ...prevValues, 
                file_name: `rnd_nft_${getCurrentDatetimeLocal()}.${extension}`,
                file_base64: image
            }));
        }
    }

    const MinterFirstPage = () => {

        return (
            <div>
                <LoadImage />

                <div className="btn-box">
                    <button className="btn no-select" onClick={handleRandomGen}>random gen</button>
                    <button className="btn no-select">ai gen</button>
                </div>
                <div className="form-box">
                    <form className="minter-form" onSubmit={async (e) => {
                        e.preventDefault()
                    if (!wallet) {
                       
                            await handleConnect();
                        }
                        else {
                            handleSubmit(e, pageNumber, {
                                name: e.target[0].value,
                                description: e.target[1].value,
                                count: e.target[2].value
                            })
                            handleComission(e.target[2].value);
                        }
                        
                    
                    

                    }
                    
                   
                    
                    }

                    
                        
                    >

                        <div className="form-item">
                            <label className="form-item-label">name</label>
                            <input className="form-input" type="text" name="name" onChange={(e) => setName(e.target.value)} defaultValue={name ? name : ""} required maxLength={64} />
                        </div>
                        <div className="form-item margin-bottom-17">
                            <label className="form-item-label">description</label>
                            <textarea ref={descriptionRef} className="form-input textarea" type="text" name="description" onChange={(e) => setDescription(e.target.value)} rows={2} value={description ? description : ""} required maxLength={256} ></textarea>
                        </div>
                        <input name="count" className="btn input-count-btn" pattern="\d*" type="text" onChange={(e) => setCount(e.target.value)} value={count} onFocus={handleFocusCount} onBlur={handleBlurCount} min={1} required />
                        <div className="arrow-box">
                            <ArrowBox pageNumber={1} />
                        </div>
                    </form>
                </div>


            </div>
        )
    }

 
   

        const handleSendToBot = async (e, descriptionOfYourPost, button) => {
            e.preventDefault()
            const sendingData = {
                qrcode: getQRCodeBase64Image(),
                description: descriptionOfYourPost,
                button: button,
                button_url: qrCodeLink,
                chat_id: chat_id,
            }
 
            await sendDataToBot(sendingData)
            
        }


        const QRCodeGenerator = ({link}) => {           
           
            console.log("QRCodeGenerator link", link)
            
            return (
              <div className="qr-container">
              
                <QRCode value={link} size={200} id="qr-code-canvas" renderAs="canvas"/>
              </div>
            );
          };

          
          const getQRCodeBase64Image = () => {
            const canvas = document.getElementById("qr-code-canvas");
            const dataURL = canvas.toDataURL("image/png");
            return dataURL;
          };
        

        

    const MinterThirdPage = () => {
        
        return (
            <>
           
            <QRCodeGenerator link={qrCodeLink}/>
                
                <div className="form-box">
                    <form className="minter-form" onSubmit={ async (e) => {
                        await handleSendToBot(e, e.target[0].value, e.target[1].value)
                
                }}>

                        <div className="form-item">
                            <label className="form-item-label">description of your post</label>
                            <textarea ref={descriptionOfYourPostRef} className="form-input textarea" rows={2} type="text" name="descriptionOfYourPost" onChange={(e) => setDescriptionOfYourPost(e.target.value)} defaultValue={descriptionOfYourPost ? descriptionOfYourPost : ""} required></textarea>
                        </div>
                        <div className="form-item margin-bottom-17">
                            <label className="form-item-label">button</label>
                            <input className="form-input" type="text" name="button" onChange={(e) => setButton(e.target.value)} defaultValue={button ? button : ""} required maxLength={256} />
                        </div>
                        <input className="btn send-to-bot-btn" type="submit" value="send to bot" />
                        <div className="arrow-box">
                            <ArrowBox pageNumber={3} />
                        </div>
                    </form>
                </div>
            </>
        )
    }
    

 
    const sendDataToServer = async () => {

        const minterData = {
            price: comission,
            collection_name: formValues.name,
            collection_description: formValues.description,
            collection_images_cnt: formValues.count,
            image_name: formValues.file_name,
            image: formValues.file_base64,
            start_date: start,
            end_date: end,
            password: password,
            subscriptions: subscriptions,
            wallet_address: tonConnectUI.account.address,
        }
        console.log("sendDataToServer", minterData)


        const apiWalletAddress = await getApiWallet()
    setApiWallet(apiWalletAddress)
    console.log("apiWalletAddress", apiWalletAddress)
    
    // Создание транзакции
    const transaction = {
        validUntil: Math.round(Date.now() / 1000) + 180,
        messages: [
            {
                address: apiWalletAddress,
                amount: comission * 1000000000,
            }
        ]
    }

    try {
        setIsTransactionError(null)
        // Ждем выполнения транзакции
        console.log("Транзакция...")
        await tonConnectUI.sendTransaction(transaction);
        console.log("Транзакция завершена.")
        
    } catch (error) {
        console.log(error);
        setIsTransactionError("error")
        return
    }
        
    setSendStatus("loading");
        //alert("Data is sent to the server...");

        // Отправка данных на сервер
        const event_id = await sendMinterData(minterData);
        
        
        if (event_id) {
            setSendStatus("success");
            console.log("event_id", event_id)
            setEventId(event_id)
            setQRCodeLink(`https://lidum.ru/checker/?event_id=${event_id}`)
            setPageNumber(pageNumber + 1)
        } else {
            setSendStatus("error");
            //alert("Error sending data!");
        }
   

            
       
    

    }
    
    const MinterSecondPage = () => {

       
        // setSendStatus(null)
        // setFormErrorMessage(null)
        // setIsTransactionError(null)

        //const comissionToUSD = tonToUSD(comission);

        return (
            
            <div className="form-box">
            <PopupMinter status={sendStatus}/>
            <PopupTransactionFailed status={isTransactionError} />
                <form className="minter-form" onSubmit={ async (e) => {
                     e.preventDefault()
                    if (!wallet) {
                       
                            await handleConnect();
                        }
                        else {
                            handleSubmit(e, pageNumber, {
                    start: start,
                    end: end,
                    password: password,
                    subscriptions: subscriptions,


                }
                
                 
                
                )
                
                        }

                    }}>

                        <div className="datetime-inputs-box">
                        <div className="form-item">
                            <label className="form-item-label">start</label>
                            <DateTimeInput input_id="startDate" name="start" defaultValue={start ? start : ""} setDateTimeInput={setStart}/>
                            
                        </div>
                        <div className="form-item">
                            <label className="form-item-label">end</label>
                            <DateTimeInput input_id="endDate" name="end" defaultValue={end ? end : ""}  setDateTimeInput={setEnd} />                          
                        </div>
                        </div>
                   


                    <div className="form-item">
                        <label className="form-item-label">password</label>
                        <input className="form-input with-square" type="text" name="password" onChange={(e) => setPassword(e.target.value)} defaultValue={password ? password : ""} required maxLength={32} />
                    </div>
                    <div className="form-item margin-bottom-32">
                        <label className="form-item-label">subscriptions</label>
                        <SubscriptionsInputBox subscriptions={subscriptions} setSubscriptions={setSubscriptions} />
                       
                        {/* <input id="subscriptions" className={subscriptions !== initial_subscriptions ? "form-input with-square" : "form-input with-square subscriptions_initial"} onChange={(e) => setSubscriptions(e.target.value)} type="text" name="subscriptions" onFocus={handleFocusSubscritions} onBlur={handleBlurSubscritions} value={subscriptions} required maxLength={256} /> */}
                    </div>

                  

                    {/* <NumberSelector setInvite={setInvite} defaultValue={invite} /> */}
                    
                    <p className="nft-text">{description ? description : ""}</p>
                    <div className="form-input comission-box">
                        <div className="comission-box-label">comission</div>
                        <div>
                            <p className="comission-ton">{comission} TON</p>
                            <p className="comission-usd">&asymp;$ {comissionToUSD}</p>
                        </div>
                    </div>
                    <div className="arrow-box">
                        <ArrowBox pageNumber={2} />
                    </div>
                </form>
            </div>

        )
    }

    const pagesArr = ["/", MinterFirstPage, MinterSecondPage, MinterThirdPage]



    const BackArrowButton = ({ pageNumber }) => {

        if (pageNumber === 1) {
            return (
                <Link to="/" className="arrow arrow-back" />
            )
        }
        return (
            <div className="arrow arrow-back" onClick={() => {
                setPageNumber(pageNumber - 1)
            }
            }>
            </div>
        )

    }

    const NextArrowButton = ({ pageNumber }) => {

        if (pageNumber >= pagesArr.length - 1) {
            return (
                <div></div>
            )
        }
        return (
            <input className="arrow arrow-next" type="submit" value="" />

            // onClick={() => {
            //     setPageNumber(pageNumber + 1)
            //     }}
        )

    }
    
    return (
        <div className="minter-box">
        
            <PopupConnectWallet status={isWalletStatus} />
            <PopupFormErrorMessage message={formErrorMessage} setStatus={setFormErrorMessage} />
            {pagesArr[pageNumber](pagesArr)}
            <TonConnectButton className='tonConnectButton' style={wallet ? {display: "none"} : {display: "block"}}/>
        </div>
    )

}
