import { useRef } from "react";


const DateTimeInput = ({input_id, name, defaultValue, setDateTimeInput}) => {
    const dateTimeInputRef = useRef(null);
  
    
    const handleChange = (event) => {
        setDateTimeInput(event.target.value);
        document.getElementById(text_id).innerHTML = formatDateTime(event.target.value)
    };


    const handleInputClick = () => {
      if (dateTimeInputRef.current) {
        dateTimeInputRef.current.showPicker(); // Для открытия календаря
      }
    };

    const formatDateTime = (dateTimeString) => {
      const date = new Date(dateTimeString);
      
      // Получаем нужные значения
      const day = String(date.getDate()).padStart(2, '0'); // День
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяц (0-11)
      const hours = String(date.getHours()).padStart(2, '0'); // Часы
      const minutes = String(date.getMinutes()).padStart(2, '0'); // Минуты

      // Формируем итоговую строку
      return `${day}.${month} ${hours}:${minutes}`;
  };

  const text_id = input_id + "_formatted";
  const text_default = defaultValue ? formatDateTime(defaultValue) : ""
    
    return (
      <>
        <input
          className="form-input with-square"
          id={input_id}
          type="datetime-local"
          name={name}
          value={defaultValue}
          required
          ref={dateTimeInputRef}
          onClick={handleInputClick} 
          onChange={handleChange}
        />

        <span className="datetime_formatted" id={text_id}>{text_default}</span>
      </>
    );
  };

  export default DateTimeInput;