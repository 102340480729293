import { TonConnectButton } from "@tonconnect/ui-react"
import twitterLogo from '../img/twitter.svg'
import telegramLogo from '../img/telegram.svg'
import { Link, Navigate, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { PopupConnectWallet } from "../components/General/PopupWindow"

const Home = ({wallet, searchParams, setChatId, isWalletStatus}) => {

   
    useEffect(() => {
        // Обновляем chatId, когда компонент монтируется или searchParams изменяется
        const chatId = searchParams.get("chat_id");
        setChatId(chatId);
    }, [searchParams, setChatId]);

    

    const navigator = useNavigate();
    return (
        <div className="home-container">
         <PopupConnectWallet status={isWalletStatus} />
        <div className="event-box" onClick={() => navigator("/minter")}><span className='event-box-text'>New event</span></div>
        <div className="buttons-box">
            <div className="link-button grow" onClick={() => navigator("/drop")}><span>Drop</span></div>
            <div className="link-button"><span>About</span></div>
        </div>

        <TonConnectButton className='tonConnectButton' style={wallet ? {display: "none"} : {display: "block"}}/>
        <div className="media-container">
            <a href="/#" className="our-media">our media</a>
            <a href="https://github.com/orgs/LidumTon/repositories" className="github" target="_blank" rel="noopener noreferrer">GitHub</a>
            <a href="/#" className="icon"><img src={twitterLogo} alt="twitter" /></a>
            <a href="https://t.me/lidumapp" className="icon" target="_blank" rel="noopener noreferrer"><img src={telegramLogo} alt="telegram" /></a>
            <a href="/#" className="docs">docs</a>
        </div>
        </div>
        
    )
}

export default Home