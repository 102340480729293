import './Header.css';
import SwitchToggle from './Header/SwitchToggle';
import logo from '../img/logo.svg'
import { PopupConnectWallet } from './General/PopupWindow';


const Header = ({ address, onDisconnect, handleConnect }) => {
    return (
        
        <header className="header">
        
            
            <span className="logo"><img src={logo} alt="logo" />Lidum app</span>
            <SwitchToggle address={address} onDisconnect={onDisconnect} handleConnect={handleConnect} />
        </header>
    )
}

export default Header