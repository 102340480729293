


export const ComissionBox = ({comission, comissionToUSD}) => {
    return (
        <>
            <div className="form-input comission-box">
                <div className="comission-box-label">comission</div>
                <div>
                    <p className="comission-ton">{comission} TON</p>
                    <p className="comission-usd">&asymp;$ {comissionToUSD}</p>
                </div>
            </div>
        </>
    )
}