import { TonConnectButton } from "@tonconnect/ui-react"
import { useState } from "react"
import { Link } from "react-router-dom"
import { DateTimeInputBox } from "../components/General/DateTimeInputBox"
import "./Drop.css"
import { ComissionBox } from "../components/General/ComissionBox"



export const Drop = ({wallet, tonConnectUI}) => {
    
    const [start, setStart] = useState("")
    const [end, setEnd] = useState("")
    const [pageNumber, setPageNumber] = useState(1)
    const [ptcToBurn, setPtcToBurn] = useState("")
    const [prizes, setPrizes] = useState("")
    const [comission, setComission] = useState(0.01)
    const [comissionToUSD, setComissionToUSD] = useState(0.02)
   
    
    const BackArrowButton = ({ pageNumber }) => {

        if (pageNumber === 1) {
            return (
                <Link to="/" className="arrow arrow-back" />
            )
        }
        return (
            <div className="arrow arrow-back" onClick={() => {
                setPageNumber(pageNumber - 1)
            }
            }>
            </div>
        )

    }

    const NextArrowButton = ({ pageNumber }) => {

        if (pageNumber >= pagesArr.length - 1) {
            return (
                <div></div>
            )
        }
        return (
            <input className="arrow arrow-next" type="submit" value="" />
        )

    }

    const handleConnect = async () => {
        try {
            await tonConnectUI.openModal()
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    };

   

    const ArrowBox = ({ pageNumber }) => {

        return (

            <div className="arrow-box">
                <BackArrowButton pageNumber={pageNumber} />
                <span></span>
            </div>

        )
    }


    const DropFormPage = () => {
        return (
            <>
                <DateTimeInputBox start={start} setStart={setStart} end={end} setEnd={setEnd} />
                <form className="form" style={{marginBottom: "17px"}} onSubmit={async (e) => {
                    e.preventDefault()
                    if (!wallet) {                       
                            await handleConnect();
                        }    
                    }}>
                    <div className="form-item">
                        <label className="form-item-label">ptc to burn</label>
                        <input
                            className="form-input"
                            type="text"
                            name="ptc_to_burn"
                            value={ptcToBurn}
                            onChange={(e) => setPtcToBurn(e.target.value)}
                            required
                            style={{textAlign: "center"}}
                            pattern="\d*"
                        />
                    </div>
                    <div className="form-item with-use-different">
                        <label className="form-item-label">prizes</label>
                        <span className="use-different">use different</span>
                        <input
                            className="form-input with-square"
                            type="text"
                            name="prizes"
                            value={prizes}
                            onChange={(e) => setPrizes(e.target.value)}
                            required
                            style={{marginBottom: "10px"}}
                        />
                    </div>
                    <p class="total-minted-owners">total minted<br/>owners</p>
                    <ComissionBox comission={comission} comissionToUSD={comissionToUSD} />
                    
                    <input className={wallet ? "submit-btn is-connect" : "submit-btn"} type="submit" value="pay and send" style={{marginBottom: "65px"}} />
                </form>
            </>
        )
    }

    const pagesArr = ["/", DropFormPage]

    return (
        <>  
            {pagesArr[pageNumber](pagesArr)}
            <ArrowBox pageNumber={pageNumber} />

            <TonConnectButton className='tonConnectButton' style={wallet ? {display: "none"} : {display: "block"}}/>

        </>
    )

}