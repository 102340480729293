import DateTimeInput from "./DateTimeInput"



export const DateTimeInputBox = ({start, setStart, end, setEnd}) => {
    return (
        <div className="datetime-inputs-box">
            <div className="form-item">
                <label className="form-item-label">start</label>
                <DateTimeInput input_id="startDate" name="start" defaultValue={start ? start : ""} setDateTimeInput={setStart}/>
                
            </div>
            <div className="form-item">
                <label className="form-item-label">end</label>
                <DateTimeInput input_id="endDate" name="end" defaultValue={end ? end : ""}  setDateTimeInput={setEnd} />                          
            </div>
        </div>
    )
}