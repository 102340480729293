import { useState } from "react";


function NumberSelector({ setInvite, defaultValue }) {
    const [number, setNumber] = useState(defaultValue);
    const [isOpen, setIsOpen] = useState(false); // Состояние для управления открытым состоянием селектора
    const numbers = Array.from({ length: 32 }, (_, i) => i + 1); // Создаем массив от 1 до 32

    const handleChange = (num) => {
        setNumber(num);
        setInvite(num);
        setIsOpen(false); // Закрыть селектор после выбора
    };

    return (
        <div className="form-item margin-bottom-32">
            <label className="form-item-label">Invite</label>
            <div
                className="form-input with-square number-selector margin-bottom-17"
                onClick={() => setIsOpen(!isOpen)}
            >
                {number} {/* Показываем текущее выбранное значение */}
            </div>
            {isOpen && (
                <div style={{display: 'flex'}}> 
                <ul className="options-list number-select">
                    {numbers.map((num) => (
                        <li
                            key={num}
                            className="number-selector-option"
                            onClick={() => handleChange(num)}
                        >
                            {num}
                        </li>
                    ))}
                </ul>
                </div>
            )}
        </div>
    );
}

export default NumberSelector;