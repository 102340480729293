import '../Header.css';
import Switch from "react-switch";
import PopupMenu from './PopupMenu';


const SwitchToggle = ({ address, onDisconnect, handleConnect }) => {
    var checked = address ? true : false
    var label = address ? address : "wallet"
  
    const handleOnChange = () => {
        checked = address ? true : false
        label = address ? address : "wallet"
    }

    return (
       
        
        <label className={checked ? 'react-switch-container react-switch-on' : 'react-switch-container react-switch-off'} onClick={handleConnect}>
        <Switch onChange={handleOnChange} className='react-switch'
            checked={checked} 
            disabled 
            uncheckedIcon={false} 
            checkedIcon={false}
            handleDiameter={5}
            height={25} 
            width={35}
            onColor="transparent"
            offColor="transparent"
            onHandleColor="#fff"
            offHandleColor="#449BF1" />
        
        
        <PopupMenu address={label} onDisconnect={onDisconnect} active={checked}/>
        </label>
        
    )
}

export default SwitchToggle