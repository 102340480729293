import { formattedDatetime } from "../../utils/appUtils"


export const CollectionPreview = ({collectionParams}) => {


    return (
    <div className="collection_description_container">
            <div className="collection_image_container info">
                <img src={collectionParams.logo} alt="Collection" className="img" id="img" />
            </div>
            <div className="collection_summary">
                <h1 className="collection_title">{collectionParams.collection_name}</h1>
                <div className="calendar-invite-container">
                    <span className="start-date">{formattedDatetime(collectionParams.start_date)}</span>
                    <span className="invites">{collectionParams.invites}</span>
                </div>
                <div className="collection_description">{collectionParams.description}</div>
            </div>
            
    </div>
    )
}

