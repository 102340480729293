import axios from "axios"
//["the-open-network"]["usd"]
const tonToUSD = async (priceInTon) => {
    try {
        const {data} = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=the-open-network&vs_currencies=usd');
    if (data) {
        const oneTon = Number(data["the-open-network"]["usd"]);
        return (priceInTon * oneTon).toFixed(5);
    }
    } catch (error) {
        console.log(error);
        return null;
    }
   
}

export default tonToUSD;