import { TonConnectButton } from "@tonconnect/ui-react"
import { useState } from "react";
import { Link } from "react-router-dom"
import logoPlaceholder from "../img/imagePlaceholder.png"
import { CollectionPreview } from "../components/General/CollectionPreview";
import { CountdownTimer } from "../components/General/CountdownTimer";



export const NftBurning = ({wallet, tonConnectUI}) => {

    const [collectionParams, setCollectionParams] = useState({
        collection_name: "My first NFT",
        description: "I need NFT-token. It should be an orange smiley face",
        logo: logoPlaceholder,
        start_date: "2024-09-01T10:00",
        end_date: "2024-09-15T07:00",
        invites: 2,
    });

    const [ptcToBurn, setPtcToBurn] = useState("0/2")

    const handlePtcToBurnChange = (e) => {
        const value = e.target.value;

        // Проверка, является ли введенное значение действительным неотрицательным числом
        if (/^\d*$/.test(value) || value === "") {
            // Если значение пустое, можно оставить "0/2" не меняя его
            // Устанавливаем число и добавляем "/2" к концу
            setPtcToBurn(value === '' ? '0' : value + "/2");
        }
    };

    const handleConnect = async () => {
        try {
            await tonConnectUI.openModal()
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    };



    return (
        <>  

         <CollectionPreview collectionParams={collectionParams}/>
        <form className="form" style={{marginBottom: "17px"}} onSubmit={(e) => e.preventDefault()}>
                <div className="form-item">
                    <label className="form-item-label">ptc to burn</label>
                    <input
                        className="form-input"
                        type="text"
                        name="ptc_to_burn"
                        value={ptcToBurn}
                        onChange={handlePtcToBurnChange}
                        required
                        style={{textAlign: "center"}}
                    />
                </div>
                <CountdownTimer end_date={collectionParams.end_date} />
                <input className={wallet ? "submit-btn is-connect" : "submit-btn"} type="submit" value="claim reward" />
            </form>

            <TonConnectButton className='tonConnectButton' style={wallet ? {display: "none"} : {display: "block"}}/>

        </>
    )
}