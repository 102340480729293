import "./CollectionInfo.css"
import placeholder from "../img/imagePlaceholder.png"
import { useEffect, useState } from "react"
import React from 'react';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { checkPassword, getUserInfo, sendDataToServer, setInitialParams } from "../utils/collectionInfoConnections";
import { SubscriptionList } from "../components/CollectionInfo/SubscriptionList";
import { CountdownTimer } from "../components/General/CountdownTimer";
import { TonConnectButton } from "@tonconnect/ui-react";
import { checkSubscriptionsNew } from "../utils/collectionInfoUtils";
import { LoadingIndicator } from "../utils/appUtils";
import { InvitesInputBox } from "../components/CollectionInfo/InvitesInputBox";
import { PopupChecker, PopupConnectWallet, PopupFormErrorMessage } from "../components/General/PopupWindow";



const formattedDatetime = (datetimeLocalString) => {
    // Преобразование строки в объект Date
    const date = new Date(datetimeLocalString);

    // Получение дня и месяца
    const day = String(date.getDate()).padStart(2, '0');    
    const month = String(date.getMonth() + 1).padStart(2, '0');

    // Форматируем результат
    return `${day}.${month}`
}





export const CollectionInfo = ({wallet, tonConnectUI, isWalletStatus}) => {

    
    

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    

    const [formErrorMessage, setFormErrorMessage] = useState(null)

    const [loadingSendNftStatus, setLoadingSendNftStatus] = useState(null)

    const [error, setError] = useState(null);

    const [isListVisible, setIsListVisible] = useState(false); // Для управления видимостью списка

    const [searchParams, setSearchParams] = useSearchParams();

    const [password, setPassword] = useState("");
    // const [invites, setInvites] = useState([""]);

    const event_id = searchParams.get("event_id") ? searchParams.get("event_id") : null;
    

    
   

    const [collectionParams, setCollectionParams] = useState({
        collection_name: "",
        description: "",
        logo: "",
        start_date: "",
        end_date: "",
        invites: 0,
        subscriptions: [],
        subscriptions_avatar_urls: [],
        minted_nfts: 1,
    });

    const [selectedSubscriptions, setSelectedSubscriptions] = useState([])

    const [walletAddress, setWalletAddress] = useState(null)



    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true); // начинаем загрузку
            try {
                await setInitialParams({ event_id, setCollectionParams });
                console.log("collectionParams", collectionParams)
            } catch (error) {
                setError(error); // устанавливаем ошибку, если нужно
                console.log(error);
                setCollectionParams({
                    collection_name: "",
                    description: "",
                    logo: "",
                    start_date: "",
                    end_date: "",
                    invites: 0,
                    subscriptions: ["@rasfocus", "@FarmerDao", "@channel1"],
                    subscriptions_avatar_urls: [placeholder, placeholder, placeholder],
                    minted_nfts: 1,
                })
            } finally {
                setIsLoading(false); // завершили загрузку
            }
        };

        fetchData();
    }, [event_id]);

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (wallet) {
                const wallet_address = tonConnectUI.account.address; // Получаем адрес кошелька
                setWalletAddress(wallet_address)
                const visited_channels = await getUserInfo(wallet_address); // Вызываем getUserInfo
                if (visited_channels) {
                    setSelectedSubscriptions(visited_channels)
                }
                
                console.log("User Info:", visited_channels); // Обрабатываем или сохраняем полученные данные
            }
        };

        fetchUserInfo(); // Вызов функции для получения информации о пользователе
    }, [wallet, tonConnectUI]);

    if (!wallet) return (
        <>
            <h2 className="connect-message">Connect to your wallet to see the collection</h2>
            <TonConnectButton className='tonConnectButton' style={wallet ? {display: "none"} : {display: "block"}}/>
        </>
    )

    if (isLoading) {
       
        return <LoadingIndicator />;
    }

    if (error) {
        
        return <div className="dataLoading">Error: {error.message}</div>;
    }

    

    const handleConnect = async (e) => {
        e.preventDefault()
        try {
            await tonConnectUI.openModal()
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    }; 

    const connect = async () => {
        try {
            await tonConnectUI.openModal()
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    }

  

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        
        if (selectedSubscriptions.length === 0) {
            setFormErrorMessage('Choose the subscriptions!')
            console.log('Choose the subscriptions!')
            return
        }
        // if (!invites || invites.length === 0) {
        //     alert('Choose your invites!')
        //     return
        // }
        // if (!checkSubscriptionsNew(invites)) {
        //     alert('Invalid invitations entered! Subscriptions must start with "@" and must not contain illegal characters!')
        //     return
        // }
        // const invites_arr = checkSubscriptionsNew(invites)


        // console.log("invites_arr", invites_arr)
        
       const isRightPass = await checkPassword(event_id, password);

        if (!isRightPass) {
            setFormErrorMessage('Invalid password!')
            return
        }

        const send_json = {
            event_id: event_id,
            wallet_address: tonConnectUI.account.address
        }

        setLoadingSendNftStatus("loading");

        // Вызов функции отправки данных
        const is_send = await sendDataToServer(send_json);
        
        // Сбрасываем состояние загрузки
       

        if (is_send) {
            setLoadingSendNftStatus("success");
        }
        else {
            setLoadingSendNftStatus("error");
        }

        
       


    };

  
  


    return (
        <>
        
        <div className="checker-box">
            <PopupConnectWallet status={isWalletStatus} />
            <PopupFormErrorMessage message={formErrorMessage}  setStatus={setFormErrorMessage}  />
            <PopupChecker status={loadingSendNftStatus} setStatus={setLoadingSendNftStatus} />            
                 <div className="collection_description_container">
                <div className="collection_image_container info">
                    <img src={collectionParams.logo} alt="Collection" className="img" id="img" />
                </div>
                <div className="collection_summary">
                    <h1 className="collection_title">{collectionParams.collection_name}</h1>
                    <div className="calendar-invite-container">
                        <span className="start-date">{formattedDatetime(collectionParams.start_date)}</span>
                        <span className="invites">{collectionParams.minted_nfts}</span>
                    </div>
                    <div className="collection_description">{collectionParams.description}</div>
                </div>
               
            </div>
            <div className="collection_data">
                    <form className="collection_data_form" onSubmit={ async (e) => {
                           setPassword(e.target[0].value) 
                         if (!wallet) {                       
                            await handleConnect(e);
                        }
                        else {

                            handleSubmit(e);
                        }
                       
                    } } >
                        <div className="form-item">
                            <label className="form-item-label">code</label>
                            <input className="form-input" type="text" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required maxLength={32} />
                        </div>
                        <div className="form-item" style={{ marginBottom: '26px' }}>
                            <label className="form-item-label">subscriptions</label>
                            <SubscriptionList 
                                subscriptions={collectionParams.subscriptions} 
                                selectedSubscriptions={selectedSubscriptions}
                                setSelectedSubscriptions={setSelectedSubscriptions} 
                                avatarUrls = {collectionParams.subscriptions_avatar_urls}
                                wallet_address = {walletAddress}
                            />
                        </div>
                        {/* <div className="form-item" style={{ marginBottom: '26px' }}>
                            <label className="form-item-label">invite</label>
                            
                            <InvitesInputBox invites={invites} setInvites={setInvites} />
                        </div> */}
                        <CountdownTimer end_date={collectionParams.end_date} />
    
                         <input className={wallet ? "submit-btn collection-get-button is-connect" : "submit-btn collection-get-button"} type="submit" value="get" />
                    </form>
    
                    
                </div>
           
            </div>
        </>
    )

    


}