import React, { useEffect, useState } from 'react';

export const CountdownTimer = ({end_date}) => {
   
    const endDate = new Date(end_date);

    
    const [timeLeft, setTimeLeft] = useState({});

    const formatTime = (time) => {
        return time < 10 ? `0${time}` : time; // Добавляем ведущий ноль, если цифра одна
    };

    const calculateTimeLeft = () => {
        const now = new Date();
        const difference = endDate - now;

        if (difference <= 0) {
            setTimeLeft({}); // Если время вышло, сбросим состояние
            return;
        }

        const hours = Math.floor(difference / (1000 * 60 * 60)); // Всего часов до endDate
        // const hours = totalHours % 24; // Часы, оставшиеся после полного дня
        const minutes = Math.floor((difference / (1000 * 60)) % 60); // Минуты
        const seconds = Math.floor((difference / 1000) % 60); // Секунды

        setTimeLeft({
            hours: formatTime(hours),
            minutes: formatTime(minutes),
            seconds: formatTime(seconds)
        });
    };

    useEffect(() => {
        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer); // Очистка таймера при размонтировании
    }, []);

    return (
        <div className="countdown-timer">
            {Object.keys(timeLeft).length === 0 ? (
                <p>Время вышло</p>
            ) : (
                <p>
                    {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
                </p>
            )}
        </div>
    );
};